<template>
  <section fluid>
    <p class="text-h6 font-museo-sans mb-0 secondary--text">
      Agregar necesidades
    </p>
    <p class="text-body-2 font-museo-sans primary--text">
      Ejercicio {{ anioFiscal }}
    </p>
    <!-- filtros -->
    <v-row align="center">
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-autocomplete
          label="OBS"
          :items="obsList"
          v-model="segmentoSeleccionado"
          outlined
          item-value="id"
          item-text="nombre"
          readonly
          @change="getCifradosOBS"
        />
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <div class="d-flex justify-end">
          <div>
            <p class="text-h4 mb-0 text-center">
              $
              {{
                Intl.NumberFormat("en-US", {
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(calculateMontoTotal || 0)
              }}
            </p>
            <p>Monto total asignado</p>
          </div>
        </div>
      </v-col>
    </v-row>
    <!-- filtros -->

    <v-row class="my-4">
      <v-col cols="12" sm="3" md="2">
        <p class="text-body-2 font-museo-sans primary--text">
          Fuentes de financiamiento
        </p>
        <v-btn
          v-if="!$route.params.onlyView"
          :disabled="!segmentoSeleccionado"
          @click="addDialog = true"
          color="secondary white--text"
          block
          >Agregar fuente</v-btn
        >
      </v-col>
    </v-row>
    <div>
      <v-data-table :headers="headers" :items="fondos">
        <template v-slot:[`item.monto`]="{ item }">
          {{
            Intl.NumberFormat("en-US", {
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(item.monto)
          }}
        </template>
        <template v-slot:[`item.monto_disponible`]="{ item }">
          {{
            Intl.NumberFormat("en-US", {
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(checkMontoDisponible(item))
          }}
        </template>
        <template v-slot:[`item.monto_ajuste`]="{ item }">
          {{
            Intl.NumberFormat("en-US", {
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(item?.FondosSolicitudNecesidad?.monto || 0)
          }}
        </template>
        <template v-slot:[`item.codigo`]="{ item }">
          <p class="mt-2 mb-0">
            {{ item.FinanciamientoUnidad?.Fondo?.cifrado_presupuestario }}
          </p>
          <v-chip
            class="my-2"
            color="warning"
            text-color="white"
            v-if="
              !item.FinanciamientoUnidad?.Fondo?.Fondo?.fu[0]?.monto_disponible
              && item.FinanciamientoUnidad?.Fondo?.tipo_fondo == 1
            "
          >
            No se ha ingresado el fondo votado
          </v-chip>
        </template>
        <template v-slot:[`item.acciones`]="{ item }">
          <div class="d-flex align-center justify-center">
            <v-tooltip
              right
              left
              v-if="
                (item.FinanciamientoUnidad?.Fondo?.Fondo?.fu[0]
                  ?.monto_disponible && !$route.params.onlyView) ||
                (item.FinanciamientoUnidad?.Fondo?.tipo_fondo == 2 &&
                item.FinanciamientoUnidad?.monto_disponible && !$route.params.onlyView)
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  @click="setajustarDialogData(item)"
                >
                  <v-icon color="secondary">mdi-wrench</v-icon>
                </v-btn>
              </template>
              <span> Ajustar </span>
            </v-tooltip>
            <v-tooltip
              v-if="!item.FondosSolicitudNecesidad && !$route.params.onlyView"
              right
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  @click="(deleteDialog = true), (detalle_seleccionado = item)"
                  :disabled="fondos.length === 1"
                >
                  <v-icon color="secondary">mdi-delete</v-icon>
                </v-btn>
              </template>
              <span> Eliminar </span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </div>
    <div class="my-10">
      <v-row>
        <v-col cols="12" sm="3" md="2">
          <v-btn
            outlined
            block
            text
            color="secondary"
            :to="{
              name: !$route.params.onlyView
                ? 'ajuste-solicitudes-necesidades'
                : 'list-solicitudes-necesidades',
            }"
            >Volver</v-btn
          >
        </v-col>
        <v-col cols="12" sm="3" md="2">
          <v-btn
            block
            color="secondary"
            @click="enviarAjuste()"
            v-if="!$route.params.onlyView"
          >
            Finalizar
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <dialogoConfirmacion
      :show="deleteDialog"
      title="¿Desea cancelar la solicitud?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="deleteDialog = false"
      @confirm="deleteDetail"
    />

    <v-dialog persistent max-width="600" class="modal" v-model="addDialog">
      <v-card max-width="600">
        <v-btn @click="(addDialog = false), clearInfo()" icon color="secondary">
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
        <v-card-text>
          <section>
            <v-row>
              <p class="text-h5 secondary--text mx-4">Agregar financiamiento</p>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  outlined
                  label="Cifrado presupuestario"
                  :items="cifradosList"
                  item-value="id"
                  item-text="cifrado"
                  v-model="fnto_unidad"
                  return-object
                  :error-messages="cifradoPresupuestarioErrors"
                  @blur="$v.fnto_unidad.$touch()"
                />
                <div class="mx-2 my-2">
                  <p v-for="ctr in fnto_unidad?.construccion">
                    {{ ctr?.codigo }} {{ ctr?.nombre }}
                  </p>
                </div>
                <div class="mx-2 pt-4 text-center">
                  <p class="text-h4 mb-0">
                    $
                    {{
                      Intl.NumberFormat("en-US", {
                        currency: "USD",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(fnto_unidad?.monto_disponible || 0)
                    }}
                  </p>
                  <p>Monto disponible</p>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <money-text-field-component
                  label="Monto a solicitar"
                  v-model="monto_agregar"
                  outlined
                  :error-messages="montoAgregarErrors"
                  @input="$v.monto_agregar.$touch()"
                />
              </v-col>
            </v-row>

            <v-row align="center">
              <v-btn
                @click="(addDialog = false), clearInfo()"
                outlined
                color="secondary"
                >Cancelar</v-btn
              >
              <v-btn
                @click="addFinanciamiento"
                color="secondary white--text"
                class="mx-4"
                >Agregar</v-btn
              >
            </v-row>
          </section>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent max-width="700" class="modal" v-model="ajustarDialog">
      <v-card max-width="700">
        <v-btn @click="ajustarDialog = false" icon color="secondary">
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
        <v-card-text>
          <section>
            <v-row>
              <p class="text-h5 secondary--text mx-4">Ajustar financiamiento</p>
            </v-row>
            <v-row>
              <v-col cols="12">
                <p class="text-subtitle-1 text-center">
                  {{ cifrado_presupuestario }}
                </p>
                <div class="mx-2 pt-4 text-center">
                  <p class="text-h4 mb-0">
                    $
                    {{
                      Intl.NumberFormat("en-US", {
                        currency: "USD",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(monto_cifrado_presupuestario || 0)
                    }}
                  </p>
                  <p>Monto disponible</p>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <money-text-field-component
                  label="Monto inicial solicitado"
                  v-model="monto_inicial_solicitado"
                  outlined
                  readonly
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <money-text-field-component
                  ref="ref_ajt_mont"
                  label="Monto a solicitar"
                  v-model="monto_ajustar"
                  outlined
                  :error-messages="montoAjustarErrors"
                  @input="$v.monto_ajustar.$touch()"
                />
              </v-col>
            </v-row>

            <v-row align="center">
              <v-btn @click="ajustarDialog = false" outlined color="secondary"
                >Cancelar</v-btn
              >
              <v-btn
                @click="ajustarFinanciamiento()"
                color="secondary white--text"
                class="mx-4"
                >Guardar</v-btn
              >
            </v-row>
          </section>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>
<script>
import DataTableComponent from "../../../components/DataTableComponent.vue";
import dialogoConfirmacion from "../../../components/ConfirmationDialogComponent.vue";
import { helpers, required } from "vuelidate/lib/validators";
import MoneyTextFieldComponent from "../../../components/MoneyTextFieldComponent.vue";
import { mapState } from "vuex";

const priceDecimals = helpers.regex(
  "priceDecimals",
  /^[0-9]+([.][0-9]{1,2})?$/
);
export default {
  name: "AddNecesidadesAjustesView",
  components: {
    DataTableComponent,
    dialogoConfirmacion,
    MoneyTextFieldComponent,
  },
  data: () => ({
    headers: [
      {
        text: "Código presupuestario",
        value: "codigo",
      },

      {
        text: "Monto($)",
        value: "monto",
        align: "right",
      },
      {
        text: "Monto disponible según presupuesto($)",
        value: "monto_disponible",
        align: "right",
      },
      {
        text: "Monto con ajuste($)",
        value: "monto_ajuste",
        align: "right",
      },

      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
      },
    ],
    fondos: [],
    deleteDialog: false,
    addDialog: false,
    obsList: [],
    segmentoSeleccionado: null,
    cifradosList: [],
    solicitudNecesidad: {
      id_unidad: null,
      id_obs: null,
      fondos: [],
    },
    fnto_unidad: null,
    monto_agregar: null,
    monto_ajustar: 0,
    monto_inicial_solicitado: null,
    detalle_seleccionado: null,
    ajustarDialog: false,
    cifrado_presupuestario: null,
    monto_cifrado_presupuestario: null,
    monto_ajuste_: null,
    obs: null,
  }),
  validations: {
    fnto_unidad: { required },
    monto_agregar: { required, priceDecimals },
    monto_ajustar: { required, priceDecimals },
  },
  computed: {
    ...mapState(["userInfo", "anioFiscal", "selectedUnidad"]),
    cifradoPresupuestarioErrors() {
      const errors = [];
      if (!this.$v.fnto_unidad.$dirty) return errors;
      !this.$v.fnto_unidad.required &&
        errors.push("El campo cifrado presupuestario es obligatorio");
      return errors;
    },
    montoAgregarErrors() {
      const errors = [];
      if (!this.$v.monto_agregar.$dirty) return errors;
      !this.$v.monto_agregar.required &&
        errors.push("El campo monto es obligatorio");
      !this.$v.monto_agregar.priceDecimals &&
        errors.push(
          "El campo solo admite enteros o decimales y 2 dígitos después del punto decimal"
        );
      return errors;
    },
    montoAjustarErrors() {
      const errors = [];
      if (!this.$v.monto_ajustar.$dirty) return errors;
      !this.$v.monto_ajustar.required &&
        errors.push("El campo monto es obligatorio");
      !this.$v.monto_ajustar.priceDecimals &&
        errors.push(
          "El campo solo admite enteros o decimales y 2 dígitos después del punto decimal"
        );
      return errors;
    },
    calculateMontoTotal() {
      if (this.fondos.length > 0) {
        return this.fondos.reduce(
          (a, b) => Number(a) + Number(b.FondosSolicitudNecesidad?.monto),
          0
        );
      }
    },
  },
  methods: {
    async getSolicitud() {
      const { status, data } =
        await this.services.PacServices.getSolicitudAjuste(
          this.$route.params.idSolicitudNecesidad
        );

      if (status == 200) {
        this.fondos = data?.fsn;
        this.segmentoSeleccionado = data?.id_obs;
        this.obs = data?.Ob?.obs;
      }
    },

    async getOBSSegmentos() {
      const { status, data } = await this.services.PacServices.getOBSSegmentos({
        pagination: false,
      });

      if (status == 200) {
        this.obsList = data.map((col) => ({
          id: col.id,
          nombre: `${col.codigo} ${col.nombre}`,
        }));
      }
    },
    async getCifradosOBS() {
      if (this.segmentoSeleccionado) {
        const { status, data } = await this.services.PacServices.getCifradosOBS(
          this.segmentoSeleccionado,
          { tipo_fondo: 2 }
        );
        if (status == 200) {
          this.cifradosList = data;
        }
      }
    },
    clearInfo() {
      this.fnto_unidad = null;
      this.monto_agregar = null;
      this.detalle_seleccionado = null;
      this.$v.$reset();
    },
    checkMonto(item) {
      return (
        Number(item.monto) ===
          Number(item.FinanciamientoUnidad?.monto_disponible) &&
        item.FondosSolicitudNecesidad == null
      );
    },

    setajustarDialogData(item) {
      this.cifrado_presupuestario = `${item.FinanciamientoUnidad?.Fondo?.cifrado_presupuestario} - ${this.obs}`;

      this.monto_cifrado_presupuestario = 0;

      if (item.FinanciamientoUnidad?.Fondo?.Fondo?.fu[0]?.monto_disponible) {
        this.monto_cifrado_presupuestario = item.FinanciamientoUnidad?.Fondo?.Fondo?.fu[0]?.monto_disponible;
      } else if (item.FinanciamientoUnidad?.Fondo?.tipo_fondo == 2) {
        this.monto_cifrado_presupuestario = item.FinanciamientoUnidad?.monto_disponible;
      }

      this.monto_inicial_solicitado = item.monto;
      this.detalle_seleccionado = item.id;
      if (item?.FondosSolicitudNecesidad?.monto)
        this.monto_ajustar = item?.FondosSolicitudNecesidad?.monto;
      this.ajustarDialog = true;
    },
    async addFinanciamiento() {
      this.$v.$touch();
      if (!this.$v.fnto_unidad.$invalid && !this.$v.monto_agregar.$invalid) {
        const { status } =
          await this.services.PacServices.postSolicitudNecesidadFondoAjuste(
            this.$route.params.idSolicitudNecesidad,
            {
              id_financiamiento_unidad: this.fnto_unidad.id,
              monto: this.monto_agregar,
            }
          );
        if (status == 201) {
          this.temporalAlert({
            show: true,
            message: `Fondo agregado con éxito`,
            type: "success",
          });
          this.getSolicitud();
        }

        this.addDialog = false;
        this.clearInfo();
      }
    },
    async ajustarFinanciamiento() {
      if (
        Number(this.monto_ajustar) > Number(this.monto_cifrado_presupuestario)
      ) {
        this.temporalAlert({
          show: true,
          type: "warning",
          message: "El monto solicitado excede del disponible",
        });
        return;
      }

      const { status } = await this.services.PacServices.postAjusteSolicitud(
        this.$route.params.idSolicitudNecesidad,
        this.detalle_seleccionado,
        {
          monto: this.monto_ajustar,
        }
      );
      if (status == 200) {
        this.temporalAlert({
          show: true,
          message: `Fondo ajustado con éxito`,
          type: "success",
        });
        this.getSolicitud();
      }
      this.clearInfo();
      this.ajustarDialog = false;
    },
    async deleteDetail() {
      if (this.fondos.length == 1) {
        this.temporalAlert({
          show: true,
          type: "warning",
          message: "No se puede eliminar el último fondo",
        });
        return;
      }

      const { status } = await this.services.PacServices.deleteSolicitudesFondo(
        this.detalle_seleccionado.id
      );
      if (status == 200) {
        this.temporalAlert({
          show: true,
          message: `Fondo eliminado con éxito`,
          type: "success",
        });
        this.getSolicitud();

        this.deleteDialog = false;
      }
    },
    async enviarAjuste() {
      if (
        this.fondos.every(
          (target) => target.FondosSolicitudNecesidad?.monto > 0
        )
      ) {
        const { status } =
          await this.services.PacServices.actualizarEstadoAjuste(
            this.$route.params.idSolicitudNecesidad
          );

        if (status == 200) {
          this.temporalAlert({
            show: true,
            message: `Ajuste de solicitud finalizado`,
            type: "success",
          });
          this.$router.back();
        }
      } else {
        this.temporalAlert({
          show: true,
          message: `Por favor realice el ajuste a todos lo fondos`,
          type: "error",
        });
      }
    },

    checkMontoDisponible(item) {
      if (item.FinanciamientoUnidad?.Fondo?.Fondo?.fu[0]?.monto_disponible) {
        return item.FinanciamientoUnidad?.Fondo?.Fondo?.fu[0]?.monto_disponible;
      } else if (item.FinanciamientoUnidad?.Fondo?.tipo_fondo == 2) {
        return item.FinanciamientoUnidad?.monto_disponible;
      } else {
        return 0;
      }
    },
  },
  watch:{
    ajustarDialog(val){
      if(!val){
        this.monto_ajustar = 0;
        this.$refs.ref_ajt_mont.updateValue(null);
      }
    }
  },
  async created() {
    if (this.$route.params.idSolicitudNecesidad) {
      await this.getSolicitud();
      this.getCifradosOBS();
    }

    this.getOBSSegmentos();
  },
};
</script>
