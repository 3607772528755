<template>
  <v-text-field
    :label="label"
    :dense="dense"
    outlined
    :value="inputValue"
    @input="handleInput"
    v-mask="currencyMask"
    :error-messages="errorMessages"
    @blur="blurHandler"
    @keydown="keydownHandler"
    @focus="focusHandler"
    prepend-inner-icon="mdi-currency-usd"
    :clearable="clearable"
    :readonly="readonly"
    :disabled="disabled"
    @click:clear="clear"
    @change="changeHandler"
    :hide-details="!showAlerts"
  />
</template>
<script>
import createNumberMask from "text-mask-addons/dist/createNumberMask";

// Definimos nuestra mascara de texto

export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String | Number,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    decimals: {
      type: Number,
      default: 2,
    },
    maxValue: {
      type: Number | null,
      default: null,
    },
    showAlerts: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    editable: false,
  }),
  computed: {
    currencyMask() {
      if (this.editable) return "";

      return createNumberMask({
        prefix: "",
        allowDecimal: true,
        includeThousandsSeparator: true,
        allowNegative: false,
        decimalLimit: this.decimals,
      });
    },
    // Definimos el valor de nuestro input
    // para que sea un numero
    inputValue: {
      get() {
        const value = parseFloat(this.value).toFixed(this.decimals).toString();
        return isNaN(value) ? "" : value;
      },
      set(value) {
        if (isNaN(value)) {
          this.$emit("input", "");
        } else {
          this.$emit("input", value);
        }
      },
    },
  },
  methods: {
    clear() {
      this.editable = true;
      this.$emit("input", null);
      this.$emit("clear");
    },
    updateValue(newValue) {
      this.editable = true;
      this.$emit("input", newValue);
    },
    handleInput(value) {
      if (value != null && value != "") {
        if (this.maxValue != null && Number(value.replaceAll(/[,]/g, "")) > this.maxValue) {
          this.temporalAlert({
            show: true,
            message: `El valor máximo permitido es ${this.maxValue}`,
            type: "error",
          })
          this.inputValue = "";
          this.updateValue("");
          this.$emit("input", "");
          return false;
        }

        if (value.indexOf(".") !== -1) {
          const decimal = value.split(".")[1];
          if (decimal.length === 0) {
            value = value + "00";
          } else if (decimal.length === 1) {
            value = value + "0";
          }
        }
        value = value.replace(/,/g, "");
      }

      this.$emit("input", value);
    },
    blurHandler() {
      this.$emit("blur");
    },
    keydownHandler(e) {
      this.editable = false;
      this.$emit("keydown", e);
    },
    changeHandler() {
      this.$emit("change");
    },
    focusHandler() {
      this.$emit("focus");
    },
  },
  created() {
    if (this.value) {
      this.$emit("input", this.value);
    }
  },
};
</script>
